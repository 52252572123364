import { defineStore } from 'pinia'

export interface DefaultPlugin {
  id: string
  path: string
  type: string
  name: string
}
export interface AddablePlugin extends DefaultPlugin {
  guideImages: string[]
  guideUrl: string
  summary: string
  description: string
  blank?: boolean
  seoTitle?: string
  seoDescription?: string
  seoKeywords?: string
}

export const usePluginsStore = defineStore('plugins', {
  state: () => {
    const config = useRuntimeConfig()
    return {
      defaultPlugins: [
        {
          id: 'main',
          path: '/main',
          type: 'Main',
          name: '메인 페이지'
        },
        {
          id: 'search',
          path: '/search',
          type: 'Search',
          name: '기업검색'
        },
        {
          id: 'plugins',
          path: '/plugins',
          type: 'Plugins',
          name: '마켓플레이스'
        }
      ] satisfies DefaultPlugin[] as DefaultPlugin[],
      addablePlugins: [
        {
          id: 'interest',
          path: `${config.public.CLIENT_CURRENT_URL}/performance-survey/performance`,
          type: 'InterestCompany',
          name: '관심리스트',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/InterestCompany1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/InterestCompany2.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/e263a66fc2d44f12bed8fd947db0988b',
          summary: '다른 기업의 정보를 실시간으로 모니터링할 수 있습니다.',
          description: `
            <p>관심 기업들을 리스트업하여 정보를 확인하고 관리할 수 있습니다.</p>
            <h3>편리한 정보관리</h3>
            <ul>
              <li>리스트에 추가한 기업의 최신 뉴스/월별 직원 수/폐업 여부/지식재산권 등을 조회할 수 있습니다.</li>
              <li>클라우드 기반으로 메모를 추가하여 기업정보를 관리할 수 있습니다.</li>
              <li>필터 기능을 통해 원하는 기업을 쉽게 검색할 수 있습니다.</li>
              <li>기업의 정보들을 Excel 파일로 추출할 수 있습니다.</li>
            </ul>
            <h3>다양한 디스플레이 형태로 공유</h3>
            <ul>
              <li>'팩트 시트' 형태로 공유할 수 있습니다.</li>
              <li>'슬라이드' 형태로 공유할 수 있습니다.</li>
            </ul>`,
          seoTitle: '관심리스트 플러그인 소개',
          seoDescription:
            '다른 기업의 정보를 실시간으로 모니터링할 수 있습니다.',
          seoKeywords: '관심리스트'
        },
        {
          id: 'analytics',
          path: '/analytics/dashboard',
          type: 'Analytics',
          name: '애널리틱스',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Analytics1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Analytics2.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Analytics3.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Analytics4.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/9093cd81f1a04e9390e8c3dc4f27663d',
          summary: '기업정보를 그룹별로 분석할 수 있습니다.',
          description: `
            <h3>관심 리스트의 기업들을 다양한 시각화 자료로 분석</h3>
            <ul>
              <li>기업의 핵심 데이터를 다양한 시각화로 제공받을 수 있습니다.</li>
              <li>기업 그룹별로 묶어 기업의 데이터를 비교할 수 있습니다.</li>
            </ul>`,
          seoTitle: '애널리틱스 플러그인 소개',
          seoDescription: '기업정보를 그룹별로 분석할 수 있습니다.',
          seoKeywords: '애널리틱스'
        },
        {
          id: 'interest-news',
          path: '/interest-news/all',
          type: 'InterestCompanyNews',
          name: '관심뉴스',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/InterestCompanyNews1.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/56e58eee86f04b64b7aed81874f54c6a',
          summary: '다른 기업의 뉴스를 실시간으로 모니터링할 수 있습니다.',
          description: `
            <h3>관심 리스트의 기업들을 다양한 시각화 자료로 분석</h3>
            <ul>
              <li>기업의 핵심 데이터를 다양한 시각화로 제공받을 수 있습니다.</li>
              <li>기업 그룹별로 묶어 기업의 데이터를 비교할 수 있습니다.</li>
            </ul>`,
          seoTitle: '관심뉴스 플러그인 소개',
          seoDescription:
            '다른 기업의 뉴스를 실시간으로 모니터링할 수 있습니다.',
          seoKeywords: '뉴스'
        },
        {
          id: 'survey',
          path: `${config.public.CLIENT_SURVEY_URL}/boards`,
          name: '신청서',
          type: 'ApplicationForm',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/ApplicationForm1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/ApplicationForm2.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/ApplicationForm3.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/ApplicationForm4.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/ApplicationForm5.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/a255f14988fc4b3ea173fac65f321635',
          summary: '구글설문지처럼 기업용 설문지를 작성할 수 있습니다.',
          description: `
            <h3>검증된 정보 수집이 가능한 설문 조사 서비스</h3>
            <p>홈텍스로 검증된 기업들에게서 필요한 정보를 쉽고 정확하게 요청하고 받아보세요. 회사명, 사업자번호, 대표자명, 투자현황 등 홈택스와 질링스의 정보를 연동하여 정확하고 빠르게 정보를 입력할수 있습니다.</p>
            <h3>편리한 신청서 보드</h3>
            <ul>
              <li>지원 사업 신청서를 &lt;구글 설문지&gt;처럼 간편하게 제작할 수 있습니다.</li>
              <li>요청 후 받은 정보를 Excel 파일로 추출 가능합니다.</li>
            </ul>
            <h3>정확하고 빠른 입력 시스템</h3>
            <ul>
              <li>참여기업의 기업정보는 &lt;질링스 데이터베이스&gt;에 보관할 수 있기 때문에 지원 사업 신청 시 기업정보를  반복으로 입력하지 않고 자동으로 입력할 수 있습니다.</li>
            </ul>`,
          seoTitle: '신청서 플러그인 소개',
          seoDescription: '구글설문지처럼 기업용 설문지를 작성할 수 있습니다.',
          seoKeywords: '신청서'
        },
        {
          id: 'ci-template',
          path: '/ci-template/',
          type: 'CompanyIntro',
          name: '회사 소개 페이지',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/CompanyIntro1.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/ab3a97f8039d4fe7a90602f2f77c347b',
          summary: '회사 소개 페이지를 간편하게 제작할 수 있습니다.',
          description: `
            <ul>
              <li>회사 소개 페이지를 간편하게 제작할 수 있습니다.</li>
              <li>뉴스 API가 연동되어 자사의 언론보도가 자동으로 업데이트됩니다.</li>
            </ul>`,
          seoTitle: '회사 소개 페이지 플러그인 소개',
          seoDescription: '회사 소개 페이지를 간편하게 제작할 수 있습니다.',
          seoKeywords: '회사 소개 페이지'
        },
        {
          id: 'portfolio',
          path: '/portfolio/all',
          type: 'Portfolio',
          name: '포트폴리오',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Portfolio1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Portfolio2.jpg'
          ],
          guideUrl:
            'https://www.notion.so/zillinks/fd5b315794d5439eb2f4e2245b6f6488',
          summary: '지원하는 포트폴리오를 간편하게 관리할 수 있습니다.',
          description: `
            <ul>
              <li>웹 사이트나 블로그에 '포트폴리오 리스트''를 삽입할 수 있습니다.</li>
              <li>포트폴리오에 등록한 기업의 정보는 기업이 직접 관리하기 때문에 담당자가 기업정보를 수정하지 않아도 됩니다.</li>
            </ul>
            <h3>이용 사례</h3>
            <ul>
              <li>http://www.enslpartners.com/portfolio</li>
              <li>https://route330ict.com/26</li>
            </ul>`,
          seoTitle: '포트폴리오 플러그인 소개',
          seoDescription: '지원하는 포트폴리오를 간편하게 관리할 수 있습니다.',
          seoKeywords: '포트폴리오'
        },
        {
          id: 'sales',
          path: '/sales',
          type: 'Sales',
          name: '매출 관리(for B2B)',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Sales1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Sales2.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Sales3.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/Sales4.jpg'
          ],
          guideUrl: '',
          summary: '홈택스 매출을 간편하게 분석할 수 있습니다.',
          description: `
            <p>B2B 기업을 위한 매출 관리 플러그인입니다.</p>
            <h3>매출 분석 차트</h3>
            <p>홈택스의 전자세금계산서 정보를 연동하여 매출을 분석할 수 있는 다양한 차트를 제공합니다.</p>`,
          seoTitle: '매출 관리(for B2B) 플러그인 소개',
          seoDescription:
            '홈택스 연결을 통해 매출을 간편하게 분석할 수 있습니다.',
          seoKeywords: '매출 관리'
        },
        {
          id: 'okceo',
          path: '/okceo',
          type: 'FinancialManagement',
          name: '재무 관리(okceo)',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/OKCEO1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/OKCEO2.jpg'
          ],
          guideUrl: '',
          summary: '내 은행 계좌를 한곳에서 확인할 수 있습니다.',
          description: `
            <ul>
              <li>OKCEO는 회계, 세법, 복식부기를 몰라도 Ai를 통해 우리회사의 재무 관리를 편하게 해줍니다.</li>
              <li>간단한 연동만으로 질링스에서 우리회사의 재무정보가 담긴 OKCEO Dashboard를 이용하실 수 있습니다.</li>
            </ul>`,
          seoTitle: '재무관리(OKCEO) 플러그인 소개',
          seoDescription:
            '다른 기업의 뉴스를 실시간으로 모니터링할 수 있습니다.',
          seoKeywords: '재무 관리'
        },
        {
          id: 'news-writing',
          path: '/news-writing',
          type: 'NewsWriting',
          name: '언론보도 작성',
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/NewsWriting1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/NewsWriting2.jpg'
          ],
          guideUrl: '',
          summary: '언론보도를 chatgpt로 작성할 수 있습니다.',
          description: `
            <h3>기업이 언론보도를 해야하는 이유</h3>
            <ol>
              <li>광고비 절약 : 다양한 미디어를 활용하여 무료로 자주 정보를 전달할 수 있어 광고 비용을 절감할 수 있습니다.</li>
              <li>미디어 관계 구축 : 보도 자료를 통해 주요 언론 매체와 관계를 구축하고 미디어를 활용하여 브랜드를 홍보할 수 있습니다.</li>
              <li>새로운 잠재고객에 접근 : 다양한 온라인 미디어를 활용하여 더 많은 잠재 고객에게 도달하고 시장을 확장할 수 있습니다.</li>
              <li>브랜드 인지도와 신뢰도 구축 : 보도 자료를 통해 브랜드의 권위와 신뢰를 높일 수 있으며, 시간이 지남에 따라 신뢰성을 강화할 수 있습니다.</li>
            </ol>
            <h3>언론보도 플러그인 장점</h3>
            <p>저장된 기업정보를 활용하여 chatgpt를 통해 언론보도를 작성합니다.</p>
            <ol>
              <li>빠르고 효율적인 보도 작성: 저장된 기업정보를 활용하면 ChatGPT는 더 빠르게 언론보도를 작성할 수 있어, 신속한 정보 전달과 업데이트가 가능해집니다.</li>
              <li>일관성과 정확성 유지: ChatGPT는 일관된 스타일과 정확한 정보 제공을 보장하여, 인간 에러를 최소화하고 전문적인 보도 내용을 생성할 수 있습니다.</li>
            </ol>`,
          seoTitle: '언론보도 작성 플러그인 소개',
          seoDescription: 'chatgpt로 전문가 수준의 기사를 작성해보세요.',
          seoKeywords: '언론보도'
        },
        {
          id: 'venture-cert-application',
          path: 'https://forms.gle/qk9haUDay1cpA7VD7',
          type: 'VentureCertApplication',
          name: '벤처인증 신청',
          blank: true,
          guideImages: [
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication1.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication2.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication3.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication4.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication5.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication6.jpg',
            'https://s3.ap-northeast-2.amazonaws.com/zillinks/assets/images/plugins-guide/VentureCertApplication7.jpg'
          ],
          guideUrl: '',
          summary: '복잡한 벤처인증을 대행으로 의뢰할 수 있습니다.',
          description: `
            <h3>벤처 인증 장점</h3>
            <ol>
              <li>전자입찰 후 "계약이행능력심사(적격심사)"시 신인도 가점</li>
              <li>조달청에서 운용중인 "벤처나라 쇼핑몰"에 상품등록 신청이 가능</li>
              <li>정부지원사업 참여시 가점</li>
              <li>세제혜택</li>
              <li>금융 지원</li>
            </ol>
            <h3>벤처 인증 신청 대행시 장점</h3>
            <ol>
              <li>합리적인 비용 : 150만원(부가세 별도)</li>
              <li>전문적인 컨설팅으로 성공률 상승</li>
              <li>처음부터 끝까지 꼼꼼한 서포트로 인한 시간 절감</li>
            </ol>`,
          seoTitle: '벤처인증 신청 플러그인 소개',
          seoDescription: '벤처인증을 대행으로 의뢰할 수 있습니다.',
          seoKeywords: '벤처인증'
        },
        {
          id: 'biz-status-check',
          path: 'https://forms.gle/ecTUHxQyd1ubkHP9A',
          type: 'BizStatusCheck',
          name: '엑셀에 기업휴페업 자동 채워넣기',
          blank: true,
          guideUrl: '',
          summary: '기업의 휴폐업 상태를 한번에 확인하세요',
          description: `<h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';">왜 우리 서비스인가요?</span></h3>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">🚀 여러분의 소중한 시간을 지켜드립니다.</span><br /><span style="font-family: 'Nanum Gothic';">복잡한&nbsp;절차와&nbsp;긴&nbsp;대기&nbsp;시간은&nbsp;이제&nbsp;그만!&nbsp;단&nbsp;몇&nbsp;번의&nbsp;클릭으로&nbsp;기업의&nbsp;폐업&nbsp;여부를&nbsp;즉시&nbsp;확인하세요.&nbsp; </span><br /><span style="font-family: 'Nanum Gothic';">우리의 혁신적인 서비스로 비즈니스 의사결정을 더욱 빠르고 정확하게 내릴 수 있습니다.</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <p data-ke-size="size18">&nbsp;</p>
          <p class="video-container"><iframe src="https://www.youtube.com/embed/aenkwXDbWvY?si=6tdcuX1kBdQo8ynT" frameborder="0" allowfullscreen=""></iframe></p>
          <h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';">주요 기능</span></h3>
          <h4 data-ke-size="size20"><span style="font-family: 'Nanum Gothic';">1. 초간단 사용법</span></h4>
          <ul style="list-style-type: disc;" data-ke-list-type="disc">
            <li><span style="font-family: 'Nanum Gothic';">사업자번호 입력</span></li>
            <li><span style="font-family: 'Nanum Gothic';">엑셀 파일 업로드</span></li>
            <li><span style="font-family: 'Nanum Gothic';">결과 확인</span></li>
          </ul>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">이게 전부입니다!</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <h4 data-ke-size="size20"><span style="font-family: 'Nanum Gothic';">2. 실시간 정보 제공</span></h4>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">최신 데이터베이스와 연동되어 항상 정확한 정보를 제공합니다.</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <h4 data-ke-size="size20"><span style="font-family: 'Nanum Gothic';">3. 대량 조회 가능</span></h4>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">한 번에 수백, 수천 개의 기업 정보도 빠르게 확인할 수 있습니다.</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <h4 data-ke-size="size20"><span style="font-family: 'Nanum Gothic';">4. 언제 어디서나</span></h4>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">PC, 태블릿, 스마트폰 등 모든 디바이스에서 이용 가능합니다.</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <p data-ke-size="size16"></p>
          <h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';">요금제</span></h3>
          <div class="table-container">
          <table style="height: 121px; width: 840px;" data-ke-align="alignLeft">
            <thead>
              <tr style="height: 21px;">
                <th style="height: 21px; width: 175px;">회원 유형</th>
                <th style="height: 21px; width: 118px;">가격</th>
                <th style="height: 21px; width: 206px;">혜택</th>
                <th style="height: 21px; width: 341px;">특징</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 20px;">
                <td style="height: 20px; width: 175px;">무료 회원</td>
                <td style="height: 20px; width: 118px;">0원</td>
                <td style="height: 20px; width: 206px;">월 50건 무료 조회</td>
                <td style="height: 20px; width: 341px;">기본 서비스 제공</td>
              </tr>
              <tr style="height: 20px;">
                <td style="height: 20px; width: 175px;">베이직</td>
                <td style="height: 20px; width: 118px;">10,000원</td>
                <td style="height: 20px; width: 206px;">월 200건 조회</td>
                <td style="height: 20px; width: 341px;">소규모 기업에 적합 (건당 50원)</td>
              </tr>
              <tr style="height: 20px;">
                <td style="height: 20px; width: 175px;">스탠다드</td>
                <td style="height: 20px; width: 118px;">25,000원</td>
                <td style="height: 20px; width: 206px;">월 600건 조회</td>
                <td style="height: 20px; width: 341px;">중규모 기업에 적합 (건당 41.7원)</td>
              </tr>
              <tr style="height: 20px;">
                <td style="height: 20px; width: 175px;">프리미엄</td>
                <td style="height: 20px; width: 118px;">40,000원</td>
                <td style="height: 20px; width: 206px;">월 1,000건 조회</td>
                <td style="height: 20px; width: 341px;">대규모 기업에 적합 (건당 40원)</td>
              </tr>
              <tr style="height: 20px;">
                <td style="height: 20px; width: 175px;">추가 조회 요금</td>
                <td style="height: 20px; width: 118px;">5,000원</td>
                <td style="height: 20px; width: 206px;">200건 추가 조회</td>
                <td style="height: 20px; width: 341px;">건당 25원</td>
              </tr>
            </tbody>
          </table>
          </div>
          <p data-ke-size="size18">&nbsp;</p>
          <h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';"></span><span style="font-family: 'Nanum Gothic';">고객의 의문에 답합니다</span></h3>
          <p data-ke-size="size18"><b><span style="font-family: 'Nanum Gothic';">Q: 사용하기 어렵지 않나요?</span></b></p>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">A: 전혀요! 누구나 쉽게 사용할 수 있도록 설계되었습니다.</span></p>
          <p data-ke-size="size18"><b><span style="font-family: 'Nanum Gothic';">Q: 데이터는 안전한가요?</span></b></p>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">A: 물론입니다. 최고 수준의 보안 프로토콜로 귀하의 정보를 안전하게 보호합니다.</span></p>
          <p data-ke-size="size18"><b><span style="font-family: 'Nanum Gothic';">Q: 별도 프로그램 설치가 필요한가요?</span></b></p>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">A: 아니요, 웹 기반 서비스로 별도 설치 없이 바로 이용 가능합니다.</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';">왜 우리 서비스를 선택해야 할까요?</span></h3>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">- ⏱️ 시간 절약: 복잡한 절차 없이 빠르게 정보 확인</span></p>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">- 💡 효율성 증대: 대량의 데이터도 한 번에 처리</span></p>
          <p data-ke-size="size18"><span style="font-family: 'Nanum Gothic';">- 👍 사용자 친화적: 누구나 쉽게 이용할 수 있는 직관적인 인터페이스</span></p>
          <p data-ke-size="size18">&nbsp;</p>
          <h3 data-ke-size="size23"><span style="font-family: 'Nanum Gothic';">지금 바로 시작하세요!</span></h3>
          <p data-ke-size="size16"><span>상단에 사전 예약을 하시면 출시 후 안내 메일이 발송됩니다.</span></p>
          <p data-ke-size="size16">&nbsp;</p>
          <p style="text-align: center;" data-ke-size="size16"><span style="font-family: 'Nanum Gothic';">&nbsp;</span></p>
          `,
          seoTitle: '휴폐업 조회 소개',
          seoDescription: '빠르고 간편한 기업 휴폐업 확인 서비스',
          seoKeywords: '휴폐업 조회',
          guideImages: []
        },
      ] satisfies AddablePlugin[] as AddablePlugin[],
      myPlugins: [] as AddablePlugin[]
    }
  },
  actions: {
    async getMyPlugins() {
      const { $Parse } = useNuxtApp()
      try {
        const res: AddablePlugin[] = await $Parse.Cloud.run('GetPluginsOfUser')
        const plugins = [
          ...this.defaultPlugins,
          ...res.map(p => this.addablePlugins.find(ap => ap.type === p.type))
        ]
        this.myPlugins = plugins as AddablePlugin[]
      } catch (error) {
        console.log('store/plugins/getMyPlugins', error)
      }
    }
  }
})
